<template>
    <pagination title="Accounts" :entities="user" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false" :with-edit-icon="updateAction.fields.filter(field => (field.name === 'right_to_work' || field.name === 'payment_type' || field.name === 'hourly_rate' || field.name === 'tax_rate' || field.name === 'accounting_code' || field.name === 'payment_voucher_email' || field.name === 'retention' || field.name === 'retention_period') && field.type !== 'hidden').size > 0" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Right To Work
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="right_to_work"
                        :action="editing? updateAction: null"
                        fieldId="right_to_work"
                        :show-label="false"
                        v-model="updateActionData['right_to_work']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Payment Type
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="payment_type"
                        :action="editing? updateAction: null"
                        fieldId="payment_type"
                        :show-label="false"
                        v-model="updateActionData['payment_type']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Payment Type Rate
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="hourly_rate"
                        :action="editing? updateAction: null"
                        fieldId="hourly_rate"
                        :show-label="false"
                        v-model="updateActionData['hourly_rate']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Overtime/Day Pay Rate
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="overtime_rate"
                        :action="editing? updateAction: null"
                        fieldId="overtime_rate"
                        :show-label="false"
                        v-model="updateActionData['overtime_rate']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Tax Rate
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="tax_rate_as_int"
                        :action="editing? updateAction: null"
                        fieldId="tax_rate"
                        :show-label="false"
                        v-model="updateActionData['tax_rate']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Accounting Code
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="accounting_code"
                        :action="editing? updateAction: null"
                        fieldId="accounting_code"
                        :show-label="false"
                        v-model="updateActionData['accounting_code']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Payment Voucher Paid Email
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="payment_voucher_paid_email"
                        :action="editing? updateAction: null"
                        fieldId="payment_voucher_email"
                        :show-label="false"
                        v-model="updateActionData['payment_voucher_email']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Retention
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="retention_as_int"
                        :action="editing? updateAction: null"
                        fieldId="retention"
                        :show-label="false"
                        v-model="updateActionData['retention']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Retention Period
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="retention_period"
                        :action="editing? updateAction: null"
                        fieldId="retention_period"
                        :show-label="false"
                        v-model="updateActionData['retention_period']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Saved Retentions
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="saved_retentions">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
  name: 'HrAccountsPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    user: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: false
      },
      editing: {
        type: Boolean,
          default: false
      },
      updateAction: {
        type: Object,
          default: null
      },
      updateActionData:{
        type: Object,
          default: null
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
