<template>
    <pagination title="Documents" :entities="documents" :hasEntities="documents && documents.count() > 0" header-colour-class="bg-transparent" :with-edit-icon="response.actions.filter(action => action.name === 'add-certificate').first()" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')" :allow-overflow="false">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>

            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Image</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Expiry Date</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Reminder Limit (months)</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Front or Back</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold"></th>
        </template>
        <template v-slot:items>
            <template v-for="(document, key) in documents">
                <DocumentsUpdate ref="certificateRow"
                             :document="document" :editing="editing"
                             :get-document-image="getDocumentImage(document.entity.properties.get('image'))"
                             :action="document.entity.actions.filter(action => action.name === 'update').first()"
                             :scope="'certificate-row-'+key" @reloadDetails="$emit('reloadDetails')" :errorsFromDocuments="errors"
                />
            </template>
            <new-document-row v-if="editing && value !== null" @removeItem="removeAddedItem(key)" ref="newCertificateItem" :response="response"
                             v-for="(value,key) in qty"
                             :key="key" :keyProp="key" :defaultValues="value"
                             :action="response" :scope="getScope(key)" :errorsFromDocuments="errors"></new-document-row>
            <tr v-if="editing">
                <td colspan="100">
                    <div class="grid grid-cols-3 p-3">
                        <custom-button @click.native="addNewItem()" data-cy="add-item"
                                       class="col-start-2 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition duration-150 ease-in-out">
                            + New Document
                        </custom-button>
                    </div>
                </td>
            </tr>

        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import DocumentsUpdate from "@/v3/components/pagination/DocumentsRow.vue";
import NewDocuments from "@/v3/components/NewDocuments.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import NewDocumentRow from "@/v3/components/pagination/NewDocumentRow.vue";

export default {
    inject: ['$validator'],
  name: 'PeopleDocumentsPagination',
    components: {
        NewDocumentRow,
        CustomButton,
        NewDocuments,
        DocumentsUpdate,
        Pagination},
    data() {
        return {
            qty: []
        }
    },
  props: {
      documents:{
        type: Object,
          default: null
      },
      editing:{
          type: Boolean,
          default: false
      },
      response:{
          type: Object,
          default: null
      }
  },
    methods:{
      getDocumentImage(image){
          if(image.includes('public')){
              return image.replace('public','storage');
          }
          return image
      },
        updateCertificates() {
            let updateItemPromises = [];

            if (this.$refs.certificateRow) {
                this.$refs.certificateRow.forEach(function (row, index) {
                    updateItemPromises.push(row.update().catch(error => {
                        throw {
                            error: error,
                            scope: 'certificate-row-' + index
                        };
                    }));
                });
            }

            if(this.$refs.newCertificateItem) {
                this.addCertificate().forEach(function (promise) {
                    updateItemPromises.push(promise);
                })
            }

            return Promise.all(updateItemPromises);
        },
        addCertificate() {
            let addNewItemPromises = [];
            if (this.$refs.newCertificateItem) {
                this.$refs.newCertificateItem.forEach(function (row, index) {
                    addNewItemPromises.push(row.create().catch(error => {
                        throw {
                            error: error,
                            scope: row.scope
                        };
                    }));
                }, this);
            }
            return addNewItemPromises;
        },
        addNewItem() {
            this.qty.push({});
            this.$emit('change');
        },
        removeAddedItem(key) {
            this.$set(this.qty, key, null);
        },
        getScope(index) {
            return 'new-certificate-row-' + index;
        },
    }
}
</script>
