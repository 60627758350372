export default {
    computed: {
        searchAction() {
            return this.response.actions.filter(action => action.name === 'search-products').first();
        },
    },
    methods: {
        updateCode(suggestion) {
            this.$refs.code.updateVal(suggestion.entity.properties.get('code'));
            this.$refs.next.$refs.fieldComponent.$refs.input.focus();
            return suggestion.entity.properties.get('id');
        },
        updateDescription(suggestion) {
            this.$refs.description.updateVal(suggestion.entity.properties.get('description'));
            this.$refs.next.$refs.fieldComponent.$refs.input.focus();
            return suggestion.entity.properties.get('id');
        }
    }
}
