<template>
    <tr>
      <td class="whitespace-normal px-3 py-4 text-sm text-left sm:pl-6">
        <template v-if="!editing">
          {{ document.entity.properties.get('name')}}
        </template>
        <template v-else>
            <property-or-field ref="name" @input="changePresetName" class="mb-2"
                               :properties="document.entity.properties"
                               :action="action" fieldId="preset-name"
                               propertyKey="preset-name"
                               v-model="updateActionData['preset-name']"
                               :scope="scope"
            ></property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.preset-name')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.preset-name')}}</div>

            <property-or-field v-if="presetName === 'custom' || updateActionData['preset-name'] === 'custom' || !updateActionData['name']" ref="name" @input="changeName"
                               :properties="document.entity.properties"
                               :action="action" fieldId="name"
                               propertyKey="name"
                               v-model="updateActionData['name']"
            :scope="scope"></property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.name')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.name')}}</div>
        </template>
      </td>
      <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
        <template v-if="!editing">
          <div>
            <download-pdf-icon-with-link v-if="document.entity.properties.get('image')"
                                         :url="getDocumentImage"></download-pdf-icon-with-link>
          </div>
        </template>
        <template v-else>
          <property-or-field
              property-key="image"
              :show-label="false"
              :properties="document.entity.properties"
              :action="document.entity.actions.filter(action => action.name === 'update').first()"
              fieldId="image"
              v-model="updateActionData['image']"
              :showAttachmentUrl="false"
              :scope="scope"
          >
          </property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.image')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.image')}}</div>

        </template>
      </td>
      <td class="whitespace-normal px-3 py-4 text-sm text-left">
        <template v-if="!editing">
          {{ document.entity.properties.get('expiry_date')}}
        </template>
        <template v-else>
          <property-or-field
              property-key="expiry_date"
              :show-label="false"
              :properties="document.entity.properties"
              :action="document.entity.actions.filter(action => action.name === 'update').first()"
              fieldId="expiry_date"
              v-model="updateActionData['expiry_date']"
              :scope="scope"
          >

          </property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.expiry_date')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.expiry_date')}}</div>

        </template>
      </td>
      <td class="whitespace-normal px-3 py-4 text-sm text-left">
        <template v-if="!editing">
          {{ document.entity.properties.get('reminder_limit')}}
        </template>
        <template v-else>
          <property-or-field
              property-key="reminder_limit"
              :show-label="false"
              :properties="document.entity.properties"
              :action="document.entity.actions.filter(action => action.name === 'update').first()"
              fieldId="reminder_limit"
              v-model="updateActionData['reminder_limit']"
              :scope="scope"
          >

          </property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.reminder_limit')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.reminder_limit')}}</div>

        </template>
      </td>
      <td class="whitespace-normal px-3 py-4 text-sm text-left">
        <template v-if="!editing">
          {{ document.entity.properties.get('front_or_back')}}
        </template>
        <template v-else>
          <property-or-field
              property-key="front_or_back"
              :show-label="false"
              :properties="document.entity.properties"
              :action="document.entity.actions.filter(action => action.name === 'update').first()"
              fieldId="front_or_back"
              v-model="updateActionData['front_or_back']"
              :scope="scope"
          >

          </property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.front_or_back')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.front_or_back')}}</div>
        </template>
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-left">
          <span class="cursor-pointer underline" v-if="editing && removeAction" @click="removeItem()">remove</span>

      </td>
    </tr>
</template>
<script>
import DownloadPdfIconWithLink from "@/v3/components/modals/DownloadPdfIconWithLink.vue"
import PropertyOrField from "@/components/PropertyOrField.vue"
import orderItemsUpdate from "@/mixins/orderItemsUpdate";

export default {
    inject: ["$validator"],
    mixins: [orderItemsUpdate],
  components: {DownloadPdfIconWithLink, PropertyOrField},
    data(){
      return {
            name: null,
            presetName: null
        }
    },
  props: {
    document: {
        type: Object,
        default: null
    },
    editing: {
        type: Boolean,
        default: false
    },
    getDocumentImage: {
        type: String,
        default: null
    },
      action :{
        type: Object,
          default: null
      },
      scope:{
        type: String,
          default: null
      },
      errorsFromDocuments:{
        type: Object,
          default: null
      }
  },
    created() {
      this.presetName = this.updateActionData['preset_name'];
    },
    computed:{
        updateActionData(){
            if (!this.action){
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        removeAction() {
            return this.document.entity.actions.filter(action => action.name === 'delete').first();
        },
    },
    methods:{
        change() {
            this.$emit('change');
        },
        changeName(value) {
            this.name = value;
            this.$emit('change');
        },
        changePresetName(value) {
            this.presetName = value;
            this.$emit('change');
        },
        update() {
            if(this.updateActionData['preset-name'] !== 'custom'){
                this.updateActionData['name'] = this.updateActionData['preset-name'];
            }
            return this.action.perform(this.updateActionData);
        },
        removeItem() {
            return this.removeAction.perform().then(res => {
                this.$store.dispatch(
                    "setMessage",
                    'Certificate removed successfully.'
                );
                this.$emit('reloadDetails');
            }).catch(error => {
                alert('There was an error processing your request.' + error);
            });
        },
    }
}
</script>
