<template>
    <pagination title="Personal" :entities="user" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false" :with-edit-icon="updateAction.fields.filter(field => (field.name === 'first_name' || field.name === 'surname' || field.name === 'date_of_birth' || field.name === 'company_email' || field.name === 'contact_number' || field.name === 'address' || field.name === 'unique_taxpayer_reference' || field.name === 'national_insurance_number') && field.type !== 'hidden').size > 0" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    First Name
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                                       :properties="user.properties"
                                       property-key="first_name"
                                       :action="editing && updateAction && updateAction.fields.filter(field => field.name === 'first_name'&& field.type !== 'hidden').first()? updateAction: null"
                                       fieldId="first_name"
                                       :show-label="false"
                                       v-model="updateActionData['first_name']">
                    </property-or-field>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Last Name/ Family Name
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="surname"
                        :action="editing && updateAction && updateAction.fields.filter(field => field.name === 'surname'&& field.type !== 'hidden').first()? updateAction: null"
                        fieldId="surname"
                        :show-label="false"
                        v-model="updateActionData['surname']">
                    </property-or-field>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Date of Birth
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="date_of_birth"
                        :action="editing && updateAction && updateAction.fields.filter(field => field.name === 'date_of_birth'&& field.type !== 'hidden').first()? updateAction: null"
                        fieldId="date_of_birth"
                        :show-label="false"
                        v-model="updateActionData['date_of_birth']">
                    </property-or-field>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Work Email
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="company_email"
                        :action="editing && updateAction && updateAction.fields.filter(field => field.name === 'company_email'&& field.type !== 'hidden').first()? updateAction: null"
                        fieldId="company_email"
                        :show-label="false"
                        v-model="updateActionData['company_email']">
                    </property-or-field>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Personal Email
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="email"
                        :action="editing && updateAction && updateAction.fields.filter(field => field.name === 'email'&& field.type !== 'hidden').first()? updateAction: null"
                        fieldId="email"
                        :show-label="false"
                        v-model="updateActionData['email']">
                    </property-or-field>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Contact Number
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="contact_number"
                        :action="editing && updateAction && updateAction.fields.filter(field => field.name === 'contact_number'&& field.type !== 'hidden').first()? updateAction: null"
                        fieldId="contact_number"
                        :show-label="false"
                        v-model="updateActionData['contact_number']">
                    </property-or-field>
                </td>
            </tr>
            <tr v-if="user.properties.get('address')">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Address
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="address"
                        :action="editing && updateAction && updateAction.fields.filter(field => field.name === 'address'&& field.type !== 'hidden').first()? updateAction: null"
                        fieldId="address"
                        :show-label="false"
                        v-model="updateActionData['address']">
                    </property-or-field>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Unique Taxpayer Reference
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="unique_taxpayer_reference"
                        :action="editing && updateAction && updateAction.fields.filter(field => field.name === 'unique_taxpayer_reference'&& field.type !== 'hidden').first()? updateAction: null"
                        fieldId="unique_taxpayer_reference"
                        :show-label="false"
                        v-model="updateActionData['unique_taxpayer_reference']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    National Insurance Number
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="user.properties"
                        property-key="national_insurance_number"
                        :action="editing && updateAction && updateAction.fields.filter(field => field.name === 'national_insurance_number'&& field.type !== 'hidden').first()? updateAction: null"
                        fieldId="national_insurance_number"
                        :show-label="false"
                        v-model="updateActionData['national_insurance_number']">
                    </property-or-field>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
  name: 'HrSinglePersonPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    user: {},
      collapsable:{
        type: Boolean,
          default: false,
      },
      open:{
        type: Boolean,
          default: false
      },
      editing: {
        type: Boolean,
          default: false
      },
      updateAction: {
        type: Object,
          default: null
      },
    updateActionData: {
        type: Object,
        default: null
    }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
