<template>
    <pagination title="Payments" :entities="payments" :hasEntities="payments && payments.count() > 0" header-colour-class="bg-transparent">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Date
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">PDF</th>
        </template>
        <template v-slot:items>
            <tr v-for="payment in payments">
                <td class="whitespace-nowrap px-3 py-4 text-sm text-left">
                    {{ payment.entity.properties.get('date')}}
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <div>
                        <download-pdf-icon-with-link v-if="payment.entity.properties.get('pdf_url')" :url="payment.entity.properties.get('pdf_url')"></download-pdf-icon-with-link>
                    </div>
                </td>
            </tr>

        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import DownloadPdfIconWithLink from "@/v3/components/modals/DownloadPdfIconWithLink.vue";
export default {
  name: 'PeoplePaymentsPagination',
    components: {
        DownloadPdfIconWithLink, Pagination},
  props: {
      payments:{
        type: Object,
          default: null
      }
  },
}
</script>
