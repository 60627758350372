<template>
    <div v-if="actionField || properties.get(propertyKey)">
        <label class="block text-sm font-medium leading-5 text-gray-400 mb-0" v-if="showLabel"
        >{{title}}</label
        >
        <div class="form-group relative" v-if="actionField">
            <component ref="fieldComponent" :is="getFieldComponent(actionField)" :field="actionField"
                       v-model="propsVal" @blur="blur()" :disabled="disabled" autocomplete="off"
                        :showLabel="showLabel"></component>

            <div data-cy="checkbox-dropdown"
                 class="static md:absolute z-20 left-0 right-0 rounded-md bg-white ring-1 ring-black ring-opacity-5 text-left overflow-y-scroll"
                 style="width:450px; max-height:450px"
                 v-show="displaySuggestions && suggestions.size > 0">
                <div class="py-1">
                    <a
                        v-for="suggestion, index in suggestions"
                        @click.prevent="suggestionClicked(suggestion)"
                        class="truncate cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        :class="{'bg-gray-100 text-gray-900': currentItem === index}"
                    >{{suggestion.entity.properties.get(propertyKey)}}</a>
                </div>
            </div>
            <div v-show="errors.has(validationFieldName)" class="text-red-600">{{ errors.first(validationFieldName) }}
            </div>
        </div>
        <div v-else>
            {{properties.get(propertyKey)}}
        </div>
    </div>
</template>

<script>

    export default {
        inject: ["$validator"],
        components: {
        },
        mounted() {
            document.addEventListener("keyup", this.nextItem);
        },
        computed: {
            actionField() {
                if (!this.action) {
                    return null;
                }
                return this.action.fields.filter(field => field.name === this.fieldId).first();
            },
            validationFieldName() {
                if (!this.scope) {
                    return this.fieldId;
                }

                return this.scope + '.' + this.fieldId;
            }
        },
        methods: {
            getFieldComponent(field) {
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            blur() {
                setTimeout(function () {
                    this.displaySuggestions = false;
                    this.currentItem = -1;
                }.bind(this), 200);
            },
            nextItem() {
                if (this.displaySuggestions) {
                    if (event.keyCode == 38 && this.currentItem > 0) {
                        this.currentItem--
                    } else if (event.keyCode == 40 && this.currentItem < this.suggestions.size - 1) {
                        this.currentItem++
                    } else if (event.keyCode == 13 && this.currentItem) {
                        this.suggestionClicked(this.suggestions.get(this.currentItem));
                    }
                }
            },
            suggestionClicked(suggestion) {
                this.$emit('suggestionSelected', suggestion);
                this.$refs.fieldComponent.updateVal(suggestion.entity.properties.get(this.propertyKey));
                this.lastUsedSuggestion = suggestion.entity.properties.get(this.propertyKey);
                this.displaySuggestions = false;
                this.currentItem = -1;
            },
            updateSuggestions(value) {
                let field = 'filter[' + this.fieldId + ']';
                this.searchAction.perform({[field]: value}).then(res => {
                    this.suggestions = res.body.entities.filter(entity => entity.rels.contains('product'));
                    this.displaySuggestions = true;
                }, this).catch(error => {
                    alert('There has been an error whilst searching for suggestions.');
                });
            },
            updateVal(value) {
                this.lastUsedSuggestion = value;
                this.$refs.fieldComponent.updateVal(value);
            }
        },
        props: {
            value: {
                type: String
            },
            title: {
                type: String
            },
            action: {
                type: Object
            },
            searchAction: {
                type: Object
            },
            fieldId: {
                type: String
            },
            propertyKey: {
                type: String
            },
            properties: {
                type: Object
            },
            scope: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            showLabel: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                propsVal: this.value,
                displaySuggestions: false,
                lastUsedSuggestion: null,
                suggestions: null,
                timeoutId: 0,
                currentItem: -1
            }
        },
        watch: {
            propsVal: {
                handler(value) {
                    clearTimeout(this.timeoutId);

                    if (value.length <= 3) {
                        this.displaySuggestions = false;
                        this.currentItem = -1;
                    }

                    if (value.length > 3 && value !== this.lastUsedSuggestion) {
                        this.timeoutId = setTimeout(function () {
                            this.updateSuggestions(value)
                        }.bind(this), 250);
                    }

                    this.$emit("input", value);
                },
                deep: true
            }
        }
    };
</script>

<style lang="scss" scoped></style>
