<template>
    <td class="px-3 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500"
        @keydown.esc="togglePDF">
        <template v-if="showPDF">
            <download-pdf :url="url"
                          @removeFromDisplay="showPDF = false">
                <template v-slot="header"><slot id="header"></slot></template>
            </download-pdf>
        </template>
        <a href="#" @click.prevent="togglePDF" target="_blank">
            <svg class="w-6 h-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
        </a>
    </td>
</template>

<script>
    import DownloadPdf from "@/v3/components/modals/DownloadPdf.vue";

    export default {
        components: {
            DownloadPdf,
        },
        props: {
            url: {
                type: String
            },
        },
        data() {
            return {
                showPDF: false
            }
        },
        methods: {
            togglePDF()
            {
                this.showPDF = !this.showPDF
            }
        },
    }
</script>

<style scoped>

</style>
