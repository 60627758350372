<template>
    <tr>
        <td class="whitespace-normal px-3 py-4 text-sm text-left sm:pl-6">
            <property-or-field ref="name" @input="changePresetName" class="mb-2"
                               :action="newItemAction"
                               fieldId="preset-name" propertyKey="preset-name"
                               v-model="newItemActionData['preset-name']"></property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.preset-name')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.preset-name')}}</div>

            <property-or-field v-if="presetName === 'custom'" ref="name" @change="changeName" :scope="scope"
                               :action="newItemAction"
                               fieldId="name" propertyKey="name"
                               v-model="newItemActionData['name']"></property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.name')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.name')}}</div>
        </td>
        <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                <property-or-field
                    property-key="image"
                    :show-label="false"
                    :action="newItemAction"
                    fieldId="image"
                    v-model="newItemActionData['image']"
                    :showAttachmentUrl="false"
                    :scope="scope"
                >
                </property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.image')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.image')}}</div>
        </td>
        <td class="whitespace-normal px-3 py-4 text-sm text-left">
            <property-or-field
                property-key="expiry_date"
                :show-label="false"
                :action="newItemAction"
                fieldId="expiry_date"
                v-model="newItemActionData['expiry_date']"
                :scope="scope"
            >

            </property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.expiry_date')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.expiry_date')}}</div>
        </td>
        <td class="whitespace-normal px-3 py-4 text-sm text-left">
                <property-or-field
                    property-key="reminder_limit"
                    :show-label="false"
                    :action="newItemAction"
                    fieldId="reminder_limit"
                    v-model="newItemActionData['reminder_limit']"
                    :scope="scope"
                >

                </property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.reminder_limit')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.reminder_limit')}}</div>

        </td>
        <td class="whitespace-normal px-3 py-4 text-sm text-left">
                <property-or-field
                    property-key="front_or_back"
                    :show-label="false"
                    :action="newItemAction"
                    fieldId="front_or_back"
                    v-model="newItemActionData['front_or_back']"
                    :scope="scope"
                >

                </property-or-field>
            <div v-show="errorsFromDocuments.has(scope +'.front_or_back')" class="text-v3-red">{{errorsFromDocuments.first(scope +'.front_or_back')}}</div>

        </td>
        <td class="whitespace-normal px-3 py-4 text-sm text-left">
            <span class="cursor-pointer underline" @click="removeItem();">remove</span>
        </td>
    </tr>
</template>

<script>
    import PropertyOrField from "@/components/PropertyOrField.vue";
    import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
    import DownloadPdfIconWithLink from "@/v3/components/modals/DownloadPdfIconWithLink.vue";
    import orderItemsUpdate from "@/mixins/orderItemsUpdate";

    export default {
        inject: ["$validator"],
        mixins: [orderItemsUpdate],
        data() {
            return {
                isSuccessfullyProcessed: false,
                name: null,
                presetName: null
            }
        },
        components: {
            DownloadPdfIconWithLink,
            PropertyOrField,
            PropertyOrAutocompleteField
        },
        props: {
            response: {
                type: Object,
            },
            scope: {
                type: String
            },
            keyProp: {
                type: Number
            },
            defaultValues: {
                type: Object,
                default: {}
            },
            errorsFromDocuments: {
                type: Object,
                default: null
            }
        },
        computed: {
            newItemAction() {
                return this.response.actions.filter(action => action.name === 'add-certificate').first();
            },
            newItemActionData() {
                if (!this.newItemAction.fields) {
                    return {};
                }
                var payload = {};
                this.newItemAction.fields.forEach(function (field) {
                    if (this.defaultValues[field.name]) {
                        payload[field.name] = this.defaultValues[field.name]
                    } else if (field.value !== undefined) {
                        payload[field.name] = field.value;
                    }
                }, this);
                return payload;
            },
        },
        methods: {
            create() {
                if (this.isSuccessfullyProcessed) {
                    return Promise.resolve();
                }
                if(this.newItemActionData['preset-name'] !== 'custom'){
                    this.newItemActionData['name'] = this.newItemActionData['preset-name'];
                }

                return this.newItemAction.perform(this.newItemActionData).then(res => {
                    this.isSuccessfullyProcessed = true;
                });
            },
            removeItem() {
                this.$emit('removeItem', this.keyProp);
            },
            changeName(value) {
                this.name = value;
                this.$emit('change');
            },
            changePresetName(value) {
                this.presetName = value;
                this.$emit('change');
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
