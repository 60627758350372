<template>
    <div v-if="userDetails" class="text-center sticky top-0 h-full grid grid-cols-3 w-full gap-2 overflow-hidden">
        <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full overflow-y-auto">
            <div class="flex flex-col h-full p-2">
                <div class="flex justify-between" v-if="hasTable">
                    <a @click="$emit('hideTable')">
                        <svg v-if="!hideTable" class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                             xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6l6 6z"/>
                        </svg>
                        <svg v-else class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                             xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M8.59 16.59L13.17 12L8.59 7.41L10 6l6 6l-6 6z"/>
                        </svg>

                    </a>
                    <a @click="openItemInNewTab">
                        <svg class="text-v3-gray-50 opacity-80 dark:text-v3-gray-400 w-6 h-6 cursor-pointer"
                             xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path fill="currentColor"
                                  d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2zM14 3v2h3.59l-9.83 9.83l1.41 1.41L19 6.41V10h2V3z"/>
                        </svg>
                    </a>
                </div>
                <div>
                    <img
                        class="inline-block h-24 w-24 rounded-full my-4" v-if="userDetails.properties.get('profile_image')" :src="'/storage/profile_images/' + userDetails.properties.get('profile_image')">
                    <img
                        class="inline-block h-24 w-24 rounded-full my-4" v-else-if="userDetails.properties.get('oss_profile_image') && userDetails.properties.get('oss_profile_image').includes('on-site-selfie')" :src="userDetails.properties.get('oss_profile_image').replace('public', 'storage')">
                    <div v-else class="flex justify-center">
                        <span
                            class="inline-flex items-center justify-center h-24 w-24 rounded-full my-4 bg-gray-400">
                            <span class="text-xl font-medium leading-none text-white">{{ initials }}</span>
                        </span>
                    </div>
                </div>
                <span class="text-black dark:text-white">{{ userDetails.properties.get('name') }}</span>
                <span v-if="userDetails.properties.get('client_name')" class="text-black dark:text-white text-opacity-50 dark:text-opacity-50">{{ userDetails.properties.get('client_name') }}</span>


                <div class="text-left grid grid-cols-3 gap-x-2">
                    <div v-for="(action, index) in remainingActions">
                        <template v-if="!action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <form-action :response="userDetails" additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="false" :onSuccessPath="$router.currentRoute.fullPath" :buttonType="getColourType(action[0], index)" @success="formSuccess"></form-action>
                        </template>
                        <template v-else-if="action[1].fields.filter(action => action.type !=='hidden').isEmpty()">
                            <different-user-sign-in-action v-if="action[0] === 'log-in-as-user'"
                                                           :action="action[1]"
                                                           :actionKey="action[0]"
                                                           :redirectOnSuccess="true"
                                                           onSuccessPath="/dashboard"
                                                            additional-button-classes="my-2 w-full"></different-user-sign-in-action>

                            <confirmation-action v-else additionalButtonClasses="my-2 w-full" :action="action[1]" :actionKey="action[0]" :redirectOnSuccess="action[0] === 'delete'" :properties="userDetails.properties" :buttonType="getColourType(action[0], index)" :on-success-path="action[0] === 'delete' ? '/' + page: $router.currentRoute.fullPath" @success="formSuccess"

                            ></confirmation-action>
                        </template>
                    </div>
                </div>

                <div class="m-2">
                    <header-link class="my-4" @click="openTab('basics')" :isActive="tabOpen === 'basics'" :activeClass="activeClass">
                        <template v-slot:title>
                            <div class="text-left">
                                Personal
                            </div>
                        </template>
                    </header-link>
                    <header-link class="my-4" @click="openTab('accounts')" :isActive="tabOpen === 'accounts'" :activeClass="activeClass">
                        <template v-slot:title>
                            <div class="text-left">
                                Accounts
                            </div>
                        </template>
                    </header-link>
                    <header-link class="my-4" @click="openTab('employment')" :isActive="tabOpen === 'employment'" :activeClass="activeClass">
                        <template v-slot:title>
                            <div class="text-left">
                                Employment
                            </div>
                        </template>
                    </header-link>
                    <header-link class="my-4" @click="openTab('documents')" :isActive="tabOpen === 'documents'" :activeClass="activeClass">
                        <template v-slot:title>
                            <div class="text-left">
                                Documents
                            </div>
                        </template>
                    </header-link>
                    <header-link class="my-4" @click="openTab('payments')" :isActive="tabOpen === 'payments'" :activeClass="activeClass">
                        <template v-slot:title>
                            <div class="text-left">
                                Payments
                            </div>
                        </template>
                    </header-link>
                </div>

            </div>
        </div>
        <div class="w-full rounded-xl bg-v3-white-100 dark:bg-v3-gray-900 h-full col-span-2 overflow-y-auto">
            <div class="m-2">

                <hr-accounts-pagination v-if="tabOpen==='accounts'" :user="userDetails" :collapsable="false" :open="accountsDetailsOpen" @toggleOpen="accountsDetailsOpen = !accountsDetailsOpen" class="my-2" :editing="editing" :updateAction="updateAction" :updateActionData="updateActionData" @editClicked="editing=true" @saveClicked="$emit('saveClicked')"></hr-accounts-pagination>
                <hr-single-person-pagination v-if="tabOpen==='basics'" :user="userDetails" :collapsable="false" :open="singleUserDetailsOpen" @toggleOpen="singleUserDetailsOpen = !singleUserDetailsOpen" class="my-2" :editing="editing" :updateAction="updateAction" :updateActionData="updateActionData" @editClicked="editing=true" @saveClicked="$emit('saveClicked')"></hr-single-person-pagination>
                <hr-person-employment-pagination v-if="tabOpen=== 'employment'" :user="userDetails" :collapsable="false" :open="employmentDetailsOpen" @toggleOpen="employmentDetailsOpen = !employmentDetailsOpen" class="my-2" :editing="editing" :updateAction="updateAction" :updateActionData="updateActionData" @editClicked="editing=true" @saveClicked="$emit('saveClicked')"></hr-person-employment-pagination>
                <people-payments-pagination v-if="tabOpen === 'payments'" :payments="payments" @collapsable="false" class="my-2"></people-payments-pagination>
                <people-documents-pagination ref="documents" v-if="tabOpen === 'documents'"  :response="userDetails" :documents="documents" @collapsable="false" class="my-2" :editing="editingDocuments" @editClicked="editDocumentsClicked" @saveClicked="saveDocumentsClicked" @reloadDetails="reloadDetails"></people-documents-pagination>
            </div>
        </div>

        <modal v-if="showModal" @removeFromDisplay="showModal = false" @perform="showModal = false" title="Unsaved Changes" confirmationString="" :has-confirmation-action="false">
            Please save changes before changing page.
        </modal>
    </div>
</template>
<script>
import HeaderLink from "@/v3/components/headers/HeaderLink.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import HrAccountsPagination from "@/v3/components/pagination/HrAccountsPagination.vue";
import HrPersonEmploymentPagination from "@/v3/components/pagination/HrPersonEmploymentPagination.vue";
import Modal from "@/v3/components/modals/Modal.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import ReviewQuotesForm from "@/v2/components/forms/ReviewQuotesForm.vue";
import EmailPreviewForm from "@/v3/components/forms/EmailPreviewForm.vue";
import ReadyForQuoteForm from "@/v2/components/forms/ReadyForQuoteForm.vue";
import UpdateScheduledFileForm from "@/v3/components/forms/UpdateScheduledFileForm.vue";
import ReturnsForm from "@/v3/components/forms/ReturnsForm.vue";
import DifferentUserSignInAction from "@/v3/components/forms/DifferentUserSignInAction.vue";
import PeoplePaymentsPagination from "@/v3/components/pagination/PeoplePaymentsPagination.vue";
import PeopleDocumentsPagination from "@/v3/components/pagination/PeopleDocumentsPagination.vue";

export default {
    inject: ["$validator"],
    components: {
        PeopleDocumentsPagination,
        PeoplePaymentsPagination,
        DifferentUserSignInAction,
        ReturnsForm,
        UpdateScheduledFileForm,
        ReadyForQuoteForm,
        EmailPreviewForm,
        ReviewQuotesForm,
        ConfirmationAction,
        FormAction,
        Modal, HrPersonEmploymentPagination, HrAccountsPagination, HrSinglePersonPagination, HeaderLink},
    props:{
        userDetails:{
            type: Object
        },
        editing:{
            type: Boolean
        },
        updateAction:{
            type: Object
        },
        hasTable:{
            type: Boolean,
            default: true
        },
        hideTable:{
            type: Boolean,
            default:false
        },
        page:{
            type: String,
            default: 'hr-people'
        },
        activeClass:{
            type: String,
            default: null
        }
    },
    data(){
        return {
            accountsDetailsOpen: false,
            singleUserDetailsOpen: false,
            employmentDetailsOpen: false,
            tabOpen: 'basics',
            showModal: false,
            editingDocuments: false

        }
    },
    computed:{
        initials() {
            if (this.userDetails && this.userDetails.properties.get('name')) {
                return this.userDetails.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        updateActionData() {
            if (!this.updateAction || !this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
        remainingActions() {
            return this.userDetails.actions.filter(action => action.name === 'reassign-user' || action.name === 'reset-user-password' || action.name === 'remove-from-client' || action.name === 'delete' || action.name === 'log-in-as-user');
        },
        payments(){
            return this.userDetails.entities.filter(entity => entity.rels.contains('pay_n_send'));
        },
        documents(){
            return this.userDetails.entities.filter(entity => entity.rels.contains('certificate'));
        }
    },
    methods:{
        openItemInNewTab(){
            let selfLink = this.userDetails.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            window.open(parts[1], '_blank');
        },
        openTab(tabName){
            if(!this.editing && !this.editingDocuments){
                this.tabOpen = tabName;
            }else{
                this.showModal = true;
            }
        },
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        formSuccess(res){
            if(res.body) {
                this.details = res.body;
                let entity = {'entity': this.details};
                this.$emit('setLoading');
                this.$emit('getDetails', entity);
            }
        },
        editDocumentsClicked(){
            this.editingDocuments = true;
        },
        saveDocumentsClicked(){
            this.$refs.documents.updateCertificates().then(res => {
                this.actionSuccess();
            }).catch(error => {
                if(error.error.status !== 422) {
                    alert('There was an error processing your request.' + error.error);
                }
                this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                this.loading = false;
            });
        },
        actionSuccess(){
            this.$store.dispatch(
                "setMessage",
                'The documents were saved successfully'
            );
            this.editingDocuments = false;
            this.reloadDetails();
            this.$refs.documents.qty = [];
        },
        reloadDetails(){
            this.editingDocuments = false;
            let entity = {entity: this.userDetails};
            this.$emit('reloadDetails', entity);
        }
    }
}
</script>
